main {
  max-width: 600px;
  margin: 0 auto;
}

.post-container {
  border: 1px solid #eee;
  margin: 8px auto;
  padding: 20px 50px;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.post-container .title {
  color: #000;
  font-weight: bold;
  margin: 0 0 10px;
  font-size: 20px;
}

.post-container .username {
  margin: 0 0 10px;
  font-size: 16px;
  color: #777;
  font-weight: bold
}

.post-container .content {
  margin: 0 0 10px;
}

.post-container .content img {
  max-width: 100%;
}

.post-container input, .post-container textarea {
  margin: 0 0 10px;
  width: 100%;
  border: none;
}

.post-container input:focus, .post-container textarea:focus {
  outline: none;
}

.post-container button {
  padding: 12px 24px;
  border: 1px solid #eee;
  background: #fff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.post-container button:hover {
  background: #eee;
  cursor: pointer;
}

.engage-container {
  margin: 10px 0;
  padding: 0
}

.engage-container .engage {
  padding: 0;
  border: none;
}

.comments-container summary {
  padding: 5px;
}

.comments-container summary {
  border-radius: 5px;
}

.comments-container summary:hover {
  cursor: pointer;
  background: #eee;
}

.comments-container .comments {
  padding: 5px;
}

.comments-container .comments .comment {
  margin: 0 0 10px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}